import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ImageComponent from '../Image/ImageComponent';

import SportPsychologist from '../../images/sportPsycologist.png';
import JustinRose from '../../images/JustinRose.png';
import SeanMcDermott from '../../images/seanMcDermott.png';
import SteveCohen from '../../images/steveCohen.png';
import JackNiklaus from '../../images/jackNicklaus.png';
import BreakingTheSlump from '../../images/breakingTheSlump.png';

// data
const carouselImages = [
  { name: SportPsychologist, alt: ' "Sport Psychologist of the Year" ' },
  { name: JustinRose, alt: 'US Open champion Justin Rose.' },
  { name: SeanMcDermott, alt: 'NFL coach of the year Sean McDermott.' },
  { name: SteveCohen, alt: 'Steve Cohen - The greatest trader of all time.' },
  { name: JackNiklaus, alt: 'The Golden Bear, Jack Nicklaus.' },
  { name: BreakingTheSlump, alt: 'Breaking the 17 year slump.' },
];

const Header = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Row>
          <Col lg={6} className="mb-3">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={400} distance="30px">
              <h1 className="hero-title">Dr. Gio Valiante</h1>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={700} distance="30px">
              <h2 className="hero-subtitle">Performance Psychologist</h2>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <p className="hero-content">
                Using Psychology to Optimize the Performance of the World’s most Elite Individuals
                and Organizations.
              </p>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1300} distance="30px">
              <p className="hero-expertise">
                <strong>Expertise: </strong> Elite Investors, Athletes, Executives, Organizations,
                Golfers, Teams, Talent evaluation, Talent development, Onboarding.
              </p>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1600} distance="30px">
              <AniLink cover direction="up" to="/contact" bg="#3486fb">
                <span className="cta-btn">BOOK NOW</span>
              </AniLink>
            </Fade>
          </Col>
          <Col lg={6}>
            <Carousel
              onSlide={(slide) => setActiveSlide(slide)}
              className="carousel mt-5 mt-lg-0"
              interval={4000}
              controls={false}
              indicators={false}
            >
              {carouselImages.map((image) => (
                <Carousel.Item>
                  <img
                    src={image.name}
                    className="carousel-image"
                    alt={image.alt}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <h2 className="carousel-captions">{carouselImages[activeSlide].alt}</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
