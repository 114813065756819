import React from 'react';
import Hero from '../components/Hero/Hero';
import Organizations from '../components/Organizations/Organizations';
import Layout from '../components/Layout';

export default () => {
  return (
    <Layout>
      <div className="take-screen-height">
        <Hero />
        <Organizations />
      </div>
    </Layout>
  );
};
