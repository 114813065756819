import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// logos imports
import InsiderLogo from '../../images/insider.svg';
import GolfLogo from '../../images/Golf.svg';
import ForbesLogo from '../../images/forbes.svg';
import YahooLogo from '../../images/yahoo.svg';
import PgaLogo from '../../images/pga.svg';
import EspnLogo from '../../images/espn.svg';

function Organizations() {
  return (
    <section className="organizations">
      <Container>
        <Row>
          <div className="text-center px-5 mt-3 mb-4 w-100">
            Dr. Gio has coached the Best of the Best from every vertical. Learn how you can work
            with him.
          </div>
        </Row>
        <Row className="align-items-center justify-center ">
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={InsiderLogo} alt="insider logo" />
          </Col>
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={GolfLogo} alt="Golf logo" />
          </Col>
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={ForbesLogo} alt="Forbes logo" />
          </Col>
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={YahooLogo} alt="Yahoo logo" />
          </Col>
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={PgaLogo} alt="Pga Logo" />
          </Col>
          <Col className="my-4" lg={2} md={4} sm={6} xs={6}>
            <img src={EspnLogo} alt="Espn logo" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Organizations;
